<template>
  <div className="container"></div>
</template>

<script>
export default {
  name: 'component'
}
</script>

<style lang="scss">
html {
  background: url('~@/assets/bg.jpg') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
